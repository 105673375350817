/* eslint-disable */
const sendMessage = (type, message) => {
	const userAgent = navigator.userAgent.toLowerCase();

	if(type == 'alarmSet') {
		if (userAgent.indexOf('android') !== -1) {
			return Android.permissionPostNotification();
		} else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
			return webkit.messageHandlers.iOS.postMessage(message);
		} else {
			// 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
			return window.opener.postMEssage(message);
		}
	} else if(type == 'locationSet') {
		if (userAgent.indexOf('android') !== -1) {
			return Android.permissionLocation();
		} else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
			return webkit.messageHandlers.iOS.postMessage(message);
		} else {
			// 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
			return window.opener.postMEssage(message);
		}
	} else if(type == 'login') {
		if (userAgent.indexOf('android') !== -1) {
			return Android.loginComplete(message.accessToken, message.refreshToken);
		} else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
			return webkit.messageHandlers.iOS.postMessage(message);
		} else {
			// 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
			return window.opener.postMEssage(message);
		}
	} else if(type == 'home') {
		if (userAgent.indexOf('android') !== -1) {
			return Android.startActivityTransition(message.wrdId, message.phn, message.accessToken, message.refreshToken);
		} else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
			return webkit.messageHandlers.iOS.postMessage(message);
		} else {
			// 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
			return window.opener.postMEssage(message);
		}
	} else if(type == 'logout') {
		if (userAgent.indexOf('android') !== -1) {
			return Android.logout();
		} else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
			return webkit.messageHandlers.iOS.postMessage(message);
		} else {
			// 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
			return window.opener.postMEssage(message);
		}
	} else if(type == 'withdrawal') {
		if (userAgent.indexOf('android') !== -1) {
			return Android.logout();
		} else if (userAgent.indexOf('iphone') !== -1 || userAgent.indexOf('ipad') !== -1) {
			return webkit.messageHandlers.iOS.postMessage(message);
		} else {
			// 안드로이드, IOS 가 아닌 경우 (더 조건을 추가해서 처리해도 됨)
			return window.opener.postMEssage(message);
		}
	}
};

export { sendMessage };
